<!--
 * @Author: zqy
 * @Date: 2023-01-17 14:52:10
 * @LastEditTime: 2023-01-17 17:37:51
 * @Description: 
 * @LastEditors: zqy
-->
<template>
	<div class="file-prview">
		<!-- 图片 -->
		<template v-if="fileType === 'img'">
			<el-image 
				style="max-width: 100%; height: auto"
				:src="url"
				fit="scale-down"
				:preview-src-list="[url]">
				<div slot="error" class="image-slot">
					<i class="el-icon-picture-outline"></i>
				</div>
			</el-image>
		</template>
		<template v-else-if="fileType === 'doc'">
			<iframe
				class="office-warp"
				:src="url.startsWith('https')? `https://weboffice.bimcc.net?ssl=1&furl=${url}` : `https://weboffice.bimcc.net?furl=${url}`"
			>
			</iframe>
		</template>
		<template v-else-if="fileType === 'mp3'">
			<audio :src="url" controls></audio>
		</template>
		<template v-else-if="fileType === 'mp4'">
			<video style="width: 100%; height: auto" :src="url" controls></video>
		</template>
		<el-empty v-else description="暂不支持预览该模式文件" />
	</div>
</template>
<script>
import { Empty, Image } from 'element-ui'
const fileTypeHash = {
	'bmp': 'img',
	'jpg': 'img',
	'png': 'img',
	'gif': 'img',
	'jpeg': 'img',
	'cdr': 'img',
	'psd': 'img',
	'doc': 'doc',
	'docx': 'doc',
	'pdf': 'doc',
	'ppt': 'doc',
	'pptx': 'doc',
	'xlsx': 'doc',
	'xls': 'doc',
	'txt': 'doc',
	'mp3': 'mp3',
	'wav': 'mp3',
	'mp4': 'mp4',
	'webm': 'mp4',
	'ogg': 'mp4'
}
export default {
	components: {
		'el-image': Image,
		'el-empty': Empty
	},
	props:{
		file:{
			type: Object,
			default: ()=>{}
		}
	},
	computed:{
		fileType() {
			if(!this.file.details || !this.file.details.path) return 
      const arr = this.file?.details?.path.substring(this.file?.details?.path.length-8,this.file?.details?.path.length).split('.')
      const pre = arr[arr.length - 1]
      return fileTypeHash[pre.toLocaleLowerCase()]
		},
		url(){
			return this.file?.details?.path
		}
	},	
	data() {
		return {
			
		};
	}
};
</script>
<style lang="less" scoped>
.file-prview{
	height: 100%;
	width: 100%;
	overflow: hidden;
}
.office-warp{
	height: 100%;
	width: 100%;
	overflow: hidden;
}
</style>
