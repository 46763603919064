<!--
 * @Author: zqy
 * @Date: 2023-01-17 11:58:34
 * @LastEditTime: 2023-01-17 17:54:44
 * @Description: 
 * @LastEditors: zqy
-->
<template>
	<div class="list-div" v-if="currentData && currentData.length">
    <ul class="list-wrap">
      <li
        v-for="(item, index) in currentData"
        :key="index"
        class="list-item"
        @click="onClickItem(item)"
      >
        <svg
          class="iconfont-svg"
          aria-hidden="true"
        >
          <use :xlink:href="getSvg(item)" />
        </svg>
        <div class="file-info">
          <p class="name">{{ item.name }}</p>
          <p class="time">{{ getDate(item.created_at) }}</p>
        </div>
      </li>
    </ul>
    <div class="fixed-wrap" v-if="drawerShow">
      <header>
        <p class="left-icon" @click="onClose">
          <i class="iconfont iconc-close"></i>
        </p>
        <p class="title">文件预览</p>
      </header>
      <article>
        <FilePrivew 
          v-if="fileShow"
          :file="currentItem"
        ></FilePrivew>
        <div v-if="contentShow" v-html="currentItem?.details?.content"></div>
      </article>
    </div>
  </div>
</template>
<script>
import FilePrivew from '@/manage-views/components/share/appShare/components/FilePrivew'
import dayjs from 'dayjs';

const fileTypeHash = {
  'jpg':'#icontupianzhaopian',
  'png':'#icontupianzhaopian',
  'bmp':'#icontupianzhaopian',
  'gif':'#icontupianzhaopian',
  'jpeg':'#icontupianzhaopian',
  'cdr':'#icontupianzhaopian',
  'psd':'#icontupianzhaopian',
  'doc':'#icona-wenjianwendang',
  'docx':'#icona-wenjianwendang',
  'ppt':'#iconppt',
  'pptx': '#iconppt',
  'pdf':'#iconpdf',
  'xlsx':'#iconbiaoge',
  'xlsm':'#iconbiaoge',
  'xls':'#iconbiaoge',
  'csv':'#iconbiaoge',
  'txt': '#icontxt',
  'mp3': '#iconyinpin',
  'wav': '#iconyinpin',
  'mp4': '#iconshipin1',
  'webm': '#iconshipin1',
  'ogg': '#iconshipin1',
}
export default {
  components:{
    FilePrivew
  },
	props: {
		list: {
			type: Array,
			default: () => []
		}
	},
	watch: {
    list:{
      handler(arr) {
        this.currentData = arr
      },
      immediate: true,
      deep: true
    }
  },
	data() {
		return {
			currentData: [],
      drawerShow: false,
      fileShow: false,
      contentShow: false
		};
	},
	methods: {
    getDate(date) {
      return dayjs(date).format('YYYY/MM/DD HH:mm')
    },  
    onClose(){
      this.drawerShow = false
      this.contentShow = false
      this.fileShow = false
    },
    getSvg(item) {
      const { type } = item
      if (type === 'word') {
        return '#iconcaogaowenjian'
      } else if (type === 'file') {
        const ext = item?.details?.path.substring(item?.details?.path.length-8,item?.details?.path.length).split('.')[1];
        return fileTypeHash[ext.toLocaleLowerCase()] || '#iconweizhiwenjian'
      } else if (type === 'folder') {
        return '#iconwenjianjia3'
      }
      return '#iconweizhiwenjian'
    },  
		changeData(data) {
			this.currentData = data;
		},
    // 点击文件
    onClickItem(item) {
      this.currentItem = item
      const { type } = item
      if (item.type === 'folder') {
        this.$emit('changeData',item)
      } else if (type === 'word') {
        this.$router.push({
          query: {
            name: item.id + item.name,
          }
        });
        this.drawerShow = true
        this.contentShow = true
      } else if (type === 'file') {
        this.$router.push({
          query: {
            name: item.id + item.name,
          }
        });
        this.drawerShow = true
        this.fileShow = true
      }
    } 
	}
};
</script>
<style lang="less" scoped>
.list-div{
  width: 100%;
  height: 100%;
}
.list-wrap {
	width: 100%;
	height: 100%;
	overflow-y: auto;
	.list-item {
		display: flex;
		align-items: center;
		padding: 12px 20px;
		.iconfont-svg {
			width: 24px;
			height: 24px;
			padding-right: 16px;
			flex-shrink: 0;
		}
		.file-info {
			flex: 1;
			.name {
				font-weight: 400;
				font-size: 16px;
				color: #000000;
				line-height: 24px;
				text-overflow: ellipsis;
				overflow: hidden;
				display: -webkit-box;
				word-wrap: break-word;
				word-break: break-all;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
			}
			.time {
				font-weight: 400;
				font-size: 12px;
				color: #a5acb3;
			}
		}
	}
}
.fixed-wrap{
  position: fixed;
  z-index: 2;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: #fff;
  display: flex;
  flex-direction: column;
  header{
    position: relative;
    width: 100%;
    height: 44px;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    background: #F4F6FA;
    flex-shrink: 0;
    .title{
      font-style: normal;
      font-weight: 500;
      font-size: 17px;
      color: #2A2F3D;
    }
    .left-icon{
      position: absolute;
      top: 12px;
      left: 17px;
      i{
        font-size: 24px;
        color: #4E5466;
      }
    }
  }
  article{
    flex: 1;
    overflow-y: auto;
  }
}
</style>
