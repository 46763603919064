<!--
 * @Author: zqy
 * @Date: 2023-01-17 10:03:17
 * @LastEditTime: 2023-01-18 16:10:24
 * @Description: 
 * @LastEditors: 朱廷果 1028509503@qq.com
-->
<template>
	<div
		class="app-share-list"
		v-loading="konwledgeLoading"
	>
		<header>
			<p
				class="left-icon"
				@click="onGoBack"
			>
				<i class="iconfont iconc-chevron-left"></i>
			</p>
			<p class="title">知识库分享</p>
		</header>
		<article>
			<section
				class="share-info"
				v-if="currentIndex === 0"
			>
				<img
					src="https://openim-1309784708.cos.ap-shanghai.myqcloud.com/2b08c41ade9817f3958fc9cf6b503292.png"
					alt=""
				/>
				<p class="info-title">
					<span class="file-num">共{{ fileNum }}个文件</span>
          <span v-if="forever" class="day-info">永久有效</span>
					<span v-else class="day-info">{{ days }}天后过期</span>
				</p>
			</section>
			<section class="list" v-loading="loadingStatus">
				<List
					ref="list"
					:list="currentList"
					@changeData="changeData"
				></List>
				<div
					class="empty-wrap"
					v-if="!(allDataList[currentIndex] && allDataList[currentIndex].length)"
				>
					<el-empty description="暂无数据"></el-empty>
				</div>
			</section>
		</article>
	</div>
</template>
<script>
import { repositoryPageWap } from '@/saas-apis/konwledgeBase.js';
import { Empty } from 'element-ui';
import List from '@/manage-views/components/share/appShare/components/ListItem';

export default {
	components: {
		'el-empty': Empty,
		List
	},
	data() {
		return {
			konwledgeLoading: false,
			uuid: this.$route.query.uuid,
			days: this.$route.query.days,
      forever:this.$route.query.forever,
			key: '',
			knowledgeId: '',
			knowledgeDetails: null,
			currentIndex: 0,
			allDataList: [],
			currentList: [],
			fileNum: 0,
			loadingStatus: false
		};
	},
	methods: {
		onGoBack() {
			let oldList = JSON.parse(JSON.stringify(this.allDataList));
			if (this.currentIndex === 0) {
				this.$router.go(-1);
			} else {
				this.currentIndex = this.currentIndex - 1;
				this.allDataList = oldList.splice(0, this.currentIndex+1);
				this.currentList = this.allDataList[this.currentIndex];
        this.$router.push({
          query: {
            id: this.currentList[0].parent_id
          }
        });
			}
		},
		// 切换数据
		changeData(data) {
			this.currentIndex = this.currentIndex + 1;
			this.loadingStatus = true;
      this.$router.push({
        query: {
          id: data.id
        }
      });
			repositoryPageWap(this.uuid, this.key, { parent_id: data.id })
				.then(res => {
					this.loadingStatus = false
					if (res.data.code === 200) {
						const data = res.data.data;
						this.knowledgeDetails = data;
						this.currentList = data;
						if (data && Array.isArray(data) && data.length) {
							this.allDataList.push(data);
						}
					}
				})
				.catch(res => {
					this.$message.error(res.data.msg);
				})
				.finally(() => {
					this.konwledgeLoading = false;
				});
		},
		getknowledgeDetails() {
			this.konwledgeLoading = true;
			repositoryPageWap(this.uuid, this.key)
				.then(res => {
					this.allDataList = [];
					// this.fileNum = 0;
					if (res.data.code === 200) {
						const data = res.data.data;
						this.knowledgeDetails = data;
						this.currentList = data;
						if (data && Array.isArray(data) && data.length) {
							this.allDataList.push(data);
              this.fileNum = data.length
              // data.forEach(item => {
              //   if (item.type !== 'folder') {
              //     this.fileNum = this.fileNum + 1
              //   }
              // })
						}
					}
				})
				.catch(res => {
					this.$message.error(res.data.msg);
				})
				.finally(() => {
					this.konwledgeLoading = false;
				});
		}
	},
	mounted() {
    window.addEventListener("popstate", () =>{
      if(this.$refs.list?.drawerShow){
        this.$refs.list.onClose()
      }else{
        this.onGoBack()
      }
      }, false);
		if (this.$route.query.uuid) {
			let pageInfo = JSON.parse(localStorage.getItem('pageInfo'));
			if (pageInfo) {
				pageInfo.forEach(item => {
					if (this.$route.query.uuid === item.uuid) {
						this.key = item.key;
						this.knowledgeId = item.repository_id;
					}
				});
			}
			if (this.knowledgeId) {
				this.getknowledgeDetails();
			}
		}
	}
};
</script>
<style lang="less" scoped>
.app-share-list {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	header {
		position: relative;
		width: 100%;
		height: 44px;
		display: flex;
		flex-shrink: 0;
		justify-content: center;
		align-items: center;
		background: #f4f6fa;
		.title {
			font-style: normal;
			font-weight: 500;
			font-size: 17px;
			color: #2a2f3d;
		}
		.left-icon {
			position: absolute;
			top: 12px;
			left: 17px;
			i {
				font-size: 24px;
				color: #4e5466;
			}
		}
	}
	article {
		width: 100%;
		flex: 1;
		display: flex;
		flex-direction: column;
		background: #f4f6fa;
		.share-info {
			padding: 12px 17px;
			img {
				height: 30px;
			}
			.info-title {
				padding-top: 12px;
				display: flex;
				align-items: center;
				.file-num {
					font-weight: 500;
					font-size: 15px;
					color: #2a2f3d;
				}
				.day-info {
					padding-left: 8px;
					font-weight: 400;
					font-size: 15px;
					color: #a9adb8;
				}
			}
		}
		.list {
			flex: 1;
			overflow-y: auto;
			background: #fff;
			border-radius: 16px 16px 0px 0px;
		}
		.wrap {
			height: 100%;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
}
</style>
